import { useCallback, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { produce } from 'immer'

import TopTagItem from 'components/top-tags/top-tag-item/top-tag-item'

import {
    getAreaOrdersChart,
    getPaymentOrdersChart,
    getSalesChannelsChart,
    getStoresChart,
} from 'helpers/performance-page'
import { useAgentsAvailable, useAuth } from 'hooks'
import { useOrdersInProgress } from 'hooks/orders'
import { useEchoConnection } from 'hooks/use-echo-connection'
import api from 'services/api'
import {
    ChartObject,
    ChartResponse,
    ReportAreaOrders,
    ReportPaymentForms,
    ReportSalesChannel,
    ReportScoreboard,
} from 'types'

import { OrdersByAreaCard, OrdersByStoreCard, OverviewCard, PaymentMethodsCard, SalesChannelsCard } from './cards'
import {
    CardRow,
    PerformanceContent,
    DashboardPageContainer,
    RowItem,
    HeaderTitle,
    HeaderContainer,
    ContainerButtons,
    ButtonOutline,
} from './dashboard-page.styles'

interface StoreChart {
    store_performance: ChartResponse
}

type Report = {
    overview?: ReportScoreboard
    salesChannels?: ChartObject
    paymentMethods?: ChartObject
    ordersByArea?: ChartObject
    ordersByStore?: ChartObject
}

type SocketData = {
    labels: string[]
    datasets: any[]
}

export default function DashboardPage(): JSX.Element {
    const { loading, reports, countAgent, scoreboard, refresh } = useFetchDashboard()
    return (
        <DashboardPageContainer>
            <HeaderContainer>
                <HeaderTitle>Dashboard</HeaderTitle>

                <ContainerButtons>
                    <TopTagItem
                        iconName="users"
                        value={countAgent}
                        label={countAgent === 1 ? 'Entregador' : 'Entregadores'}
                    />
                    <ButtonOutline onClick={refresh}>
                        <FontAwesomeIcon icon="redo" />
                    </ButtonOutline>
                </ContainerButtons>
            </HeaderContainer>

            <PerformanceContent>
                <CardRow>
                    <OverviewCard loading={loading} data={reports.overview} scoreboard={scoreboard} />
                </CardRow>
                <CardRow>
                    <RowItem>
                        <SalesChannelsCard loading={loading} chart={reports.salesChannels} />
                    </RowItem>
                    <RowItem>
                        <PaymentMethodsCard loading={loading} chart={reports.paymentMethods} />
                    </RowItem>
                </CardRow>
                <CardRow>
                    <RowItem>
                        <OrdersByAreaCard loading={loading} chart={reports.ordersByArea} />
                    </RowItem>
                    <RowItem>
                        <OrdersByStoreCard loading={loading} chart={reports.ordersByStore} />
                    </RowItem>
                </CardRow>
            </PerformanceContent>
        </DashboardPageContainer>
    )
}

function useFetchDashboard() {
    const { mall } = useAuth()

    const { agents } = useAgentsAvailable()
    const { scoreboard, revalidateOrders } = useOrdersInProgress()

    const [loading, setLoading] = useState(true)

    const [reports, setReports] = useState<Report>({})

    const filters = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return {
            malls: [mall?.id],
            start_date: query.get('start_date') || format(new Date(), 'YYYY-MM-DD'),
            end_date: query.get('end_date') || format(new Date(), 'YYYY-MM-DD'),
            start_time: query.get('start_time') || undefined,
            end_time: query.get('end_time') || undefined,
        }
    }, [mall?.id])

    const _loadOverview = useCallback(
        async (params: any) => {
            try {
                const { data } = await api.get<ReportScoreboard>('/painel/report/scoreboard', { params })
                setReports(
                    produce(state => {
                        state.overview = data
                    })
                )
            } catch (error) {
                console.log(error)
            }
        },
        [setReports]
    )

    const _loadSalesChannels = useCallback(
        async (params: any) => {
            try {
                const { data } = await api.get<ReportSalesChannel>('/painel/report/sales-channel', { params })
                const chart = getSalesChannelsChart(data)
                setReports(
                    produce(state => {
                        state.salesChannels = chart
                    })
                )
            } catch (error) {
                console.log(error)
            }
        },
        [setReports]
    )

    const _loadPaymentMethods = useCallback(
        async (params: any) => {
            try {
                const { data } = await api.get<ReportPaymentForms>('/painel/report/payment-orders', { params })
                const chart = getPaymentOrdersChart(data)
                setReports(
                    produce(state => {
                        state.paymentMethods = chart
                    })
                )
            } catch (error) {
                console.log(error)
            }
        },
        [setReports]
    )

    const _loadOrdersByArea = useCallback(
        async (params: any) => {
            try {
                const response = await api.get<ReportAreaOrders>('/painel/report/area-orders', { params })
                const chart = getAreaOrdersChart(response.data)
                setReports(
                    produce(state => {
                        state.ordersByArea = chart
                    })
                )
            } catch (error) {
                console.log(error)
            }
        },
        [setReports]
    )

    const _loadOrdersByStore = useCallback(
        async (params: any) => {
            try {
                const { data } = await api.get<StoreChart>('/painel/report/store', {
                    params: { ...params, with_canceled: 1 },
                })
                const chart = getStoresChart(data.store_performance)
                setReports(
                    produce(state => {
                        state.ordersByStore = chart
                    })
                )
            } catch (error) {
                console.log(error)
            }
        },
        [setReports]
    )

    const socketEvents = useMemo(() => {
        return [
            {
                name: '.header-scoreboard',
                callback: (data: ReportScoreboard) => {
                    setReports(
                        produce(state => {
                            state.overview = data
                        })
                    )
                },
            },
            {
                name: '.payment-area-scoreboard',
                callback: (payload: SocketData) => {
                    if (!payload.labels?.length) {
                        return
                    }
                    setReports(
                        produce(state => {
                            state.ordersByArea.data = payload
                        })
                    )
                },
            },
            {
                name: '.payment-scoreboard',
                callback: (payload: SocketData) => {
                    if (!payload.labels?.length) {
                        return
                    }
                    setReports(
                        produce(state => {
                            state.paymentMethods.data = payload
                        })
                    )
                },
            },
            {
                name: '.sale-channel-scoreboard',
                callback: (payload: SocketData) => {
                    if (!payload.labels?.length) {
                        return
                    }

                    setReports(
                        produce(state => {
                            state.salesChannels.data = payload
                        })
                    )
                },
            },
            {
                name: '.stores-scoreboard',
                callback: (payload: SocketData) => {
                    if (!payload.labels?.length) {
                        return
                    }

                    setReports(
                        produce(state => {
                            state.ordersByStore.data = payload
                        })
                    )
                },
            },
        ]
    }, [])

    useEchoConnection<any, any>({
        channelName: mall.id ? `orders.mall.${mall.id}` : undefined,
        events: socketEvents,
    })

    const _getData = useCallback(async () => {
        setLoading(true)
        try {
            await Promise.all([
                _loadOverview(filters),
                _loadSalesChannels(filters),
                _loadPaymentMethods(filters),
                _loadOrdersByArea(filters),
                _loadOrdersByStore(filters),
            ])
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const _refresh = useCallback(() => {
        _getData()
        revalidateOrders()
    }, [_getData, revalidateOrders])

    useEffect(() => {
        _getData()
    }, [_getData])

    return { loading, reports, countAgent: agents.items.length, scoreboard, refresh: _refresh }
}
