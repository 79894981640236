import React, { memo } from 'react'

import { AmountCard, LoadingMessage, PerformanceCard } from 'components/_common'

import { formatCurrency } from 'helpers'
import { OrdersScoreboard } from 'store/reducers/ordersInProgress'
import { ReportScoreboard } from 'types'

import { AmountRow, AmountItem } from './overview-card.styled'

interface Props {
    data?: ReportScoreboard
    loading?: boolean
    scoreboard?: OrdersScoreboard
}
const OverviewCard: React.FC<Props> = memo(({ data, loading, scoreboard }) => {
    return (
        <PerformanceCard title="Pedidos" subtitle="Visão geral da operação" fitHeight>
            {loading ? (
                <LoadingMessage />
            ) : (
                <>
                    {!!scoreboard && (
                        <AmountRow>
                            <AmountItem>
                                <AmountCard number={scoreboard.scheduled} title="Agendados" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={scoreboard.in_production} title="Em Produção" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={scoreboard.to_collect} title="Pronto p/ Coleta" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={scoreboard.routed} title="Aguardando Coleta" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard
                                    number={scoreboard.in_progress + scoreboard.failed + scoreboard.at_client}
                                    title="Em rota"
                                />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard
                                    number={scoreboard.delivered + scoreboard.takeout + scoreboard.finished}
                                    title="Entregues"
                                />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={scoreboard.canceled} title="Cancelados" />
                            </AmountItem>
                        </AmountRow>
                    )}
                    {!!data && (
                        <AmountRow>
                            <AmountItem>
                                <AmountCard number={formatCurrency(data.ticket_average)} title="Ticket Médio" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={data.time_average} title="Tempo Médio de Preparo" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={data.collect_time_average} title="Tempo Médio de Coleta" />
                            </AmountItem>
                        </AmountRow>
                    )}
                </>
            )}
        </PerformanceCard>
    )
})

export { OverviewCard }
